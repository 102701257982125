export const USER_DATA = {
    email: null,
    rank: null,
    referralId: null,
    fromWaitList: false
}

export const API_ENDPOINTS = {
    contactUs: '/contact-us-request',
    blogs: '/blogs',
    dataDeletion: '/user/data-deletion-request',
    generateOtp: '/user/generate-otp/',
    verifyOtp: '/user/verify-otp/'
}

export const USERS_COUNT = 43000