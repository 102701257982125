import { createStore } from 'vuex'
import { USER_DATA } from '@/constants/common'

export default createStore({
  state: {
    userData: {...USER_DATA}
  },
  getters: {
    getUserData (state) {
      return state.userData
    }
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.userData = userData
    },
  },
  actions: {
    setUserData({ commit }, userData) { 
      commit('SET_USER_DATA', userData)
    },
  },
  modules: {
  }
})
